import { Navigate, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { ForgetPasswordScreen } from 'src/auth/login/ForgetPasswordScreen'
import { LoginScreen } from 'src/auth/login/LoginScreen'
import { ResetPasswordScreen } from 'src/auth/login/ResetPasswordScreen'
import { useAuth } from 'src/auth/useAuth'
import { Dashboard } from 'src/dashboard/Dashboard'
import { MediaLibraryScreen } from 'src/media/MediaLibraryScreen'
import { Playlists } from 'src/playlists/Playlists'
import { EditPlaylist } from 'src/playlists/edit/EditPlaylist'
import { Screens } from 'src/screens/Screens'
import { Navigation } from './Navigation'

const Wrapper = styled.main`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

interface Props {
  onToggleSidebar: () => void
}

export function Main({ onToggleSidebar }: Props) {
  const { isLoggedIn } = useAuth()

  return (
    <Wrapper>
      {isLoggedIn !== null && (
        <Routes>
          {isLoggedIn ? (
            <Route
              path="/"
              element={<Navigation onToggleSidebar={onToggleSidebar} />}
            >
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="slideshows">
                <Route index element={<Playlists />} />
                <Route path=":id" element={<EditPlaylist />} />
                <Route path=":id/add" element={<EditPlaylist />} />
                <Route path=":id/edit" element={<EditPlaylist />} />
              </Route>
              <Route path="screens" element={<Screens />}>
                <Route path="add" element={<Screens />} />
                <Route path="play" element={<Screens />} />
                <Route path="pair" element={<Screens />} />
              </Route>
              <Route path="media">
                <Route index element={<MediaLibraryScreen />} />
                <Route path=":id" element={<MediaLibraryScreen />} />
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
          ) : (
            <>
              <Route path="/login" element={<LoginScreen />} />
              <Route
                path="/forget-password"
                element={<ForgetPasswordScreen />}
              />
              <Route path="/reset-password" element={<ResetPasswordScreen />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      )}
    </Wrapper>
  )
}
