import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { useSlideshowsWithSlides } from 'src/hooks/api/query/useSlideshowsWithSlides'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { PlaylistList } from './PlaylistList'

const Header = styled.div`
  margin-bottom: 60px;
`

export function Playlists() {
  useScrollToTop()

  const { t } = useTranslation()
  const playlistsQuery = useSlideshowsWithSlides()

  const playlists = playlistsQuery.data?.slideshows

  let navigate = useNavigate()

  const editPlaylist = useCallback(
    (playlistId: string) => {
      navigate(`/slideshows/${playlistId}`)
    },
    [navigate]
  )

  return (
    <div>
      <Header>
        <TitleH2>{t('playlists.title')}</TitleH2>
        <SubTitle>
          {playlists ? (
            t('playlists.subtitle', {
              count: playlists.length,
            })
          ) : (
            <>&nbsp;</>
          )}
        </SubTitle>
      </Header>
      <PlaylistList playlists={playlists} onSelect={editPlaylist} />
    </div>
  )
}
