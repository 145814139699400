/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Media = {
  __typename?: 'Media';
  created: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  thumbnailUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type MediaThumbnailUrlArgs = {
  insecure?: Scalars['Boolean']['input'];
};


export type MediaUrlArgs = {
  insecure?: Scalars['Boolean']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addScreen: Screen;
  addSlideshow: Slideshow;
  editSlides: Slideshow;
  registerDevice: RegistrationResult;
  renameSlideshow: Slideshow;
  setSlideDuration: Slideshow;
  setSlideshow: Screen;
  updateTelemetry: Screen;
};


export type MutationAddScreenArgs = {
  name: Scalars['String']['input'];
};


export type MutationAddSlideshowArgs = {
  name: Scalars['String']['input'];
};


export type MutationEditSlidesArgs = {
  id: Scalars['ID']['input'];
  slides: Array<SlideInput>;
};


export type MutationRegisterDeviceArgs = {
  screenId: Scalars['ID']['input'];
  userCode: Scalars['String']['input'];
};


export type MutationRenameSlideshowArgs = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationSetSlideDurationArgs = {
  durationMs: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};


export type MutationSetSlideshowArgs = {
  screenId: Scalars['ID']['input'];
  slideshowId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateTelemetryArgs = {
  telemetry: TelemetryInput;
};

export type Query = {
  __typename?: 'Query';
  getAllMedia: Array<Media>;
  screenContent: ScreenContent;
  screens: Array<Screen>;
  slideshow: Slideshow;
  slideshows: Array<Slideshow>;
  uploadMediaRequest: S3PreSignedUploadRequest;
};


export type QuerySlideshowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUploadMediaRequestArgs = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  deviceCode: Scalars['String']['output'];
};

export type S3PreSignedUploadRequest = {
  __typename?: 'S3PreSignedUploadRequest';
  fields: Array<KeyValuePair>;
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Screen = {
  __typename?: 'Screen';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slideshow?: Maybe<Slideshow>;
  telemetry: Telemetry;
};

export type ScreenContent = {
  __typename?: 'ScreenContent';
  screenId: Scalars['ID']['output'];
  slideDurationMs?: Maybe<Scalars['Float']['output']>;
  slides?: Maybe<Array<Slide>>;
};

export type Slide = {
  __typename?: 'Slide';
  id: Scalars['ID']['output'];
  media: Media;
};

export type SlideInput = {
  id: Scalars['ID']['input'];
  mediaId: Scalars['String']['input'];
};

export type Slideshow = {
  __typename?: 'Slideshow';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slideDurationMs: Scalars['Float']['output'];
  slides: Array<Slide>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onDeviceRegistered: RegistrationResult;
  onMediaChanged: Media;
  onScreenContent: ScreenContent;
};


export type SubscriptionOnDeviceRegisteredArgs = {
  deviceCode: Scalars['String']['input'];
};


export type SubscriptionOnMediaChangedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionOnScreenContentArgs = {
  screenId: Scalars['ID']['input'];
};

export type Telemetry = {
  __typename?: 'Telemetry';
  resolution?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type TelemetryInput = {
  resolution: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type AddScreenMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type AddScreenMutation = { __typename?: 'Mutation', addScreen: { __typename?: 'Screen', id: string, name: string } };

export type AddSlideshowMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type AddSlideshowMutation = { __typename?: 'Mutation', addSlideshow: { __typename?: 'Slideshow', id: string, name: string } };

export type EditSlidesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  slides: Array<SlideInput> | SlideInput;
}>;


export type EditSlidesMutation = { __typename?: 'Mutation', editSlides: { __typename?: 'Slideshow', id: string, slides: Array<{ __typename?: 'Slide', id: string, media: { __typename?: 'Media', id: string, url: string, thumbnailUrl: string } }> } };

export type _Fragment = { __typename?: 'Media', id: string, url: string, thumbnailUrl: string } & { ' $fragmentName'?: '_Fragment' };

export type RegisterDeviceMutationVariables = Exact<{
  screenId: Scalars['ID']['input'];
  code: Scalars['String']['input'];
}>;


export type RegisterDeviceMutation = { __typename?: 'Mutation', registerDevice: { __typename?: 'RegistrationResult', deviceCode: string } };

export type RenameSlideshowMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameSlideshowMutation = { __typename?: 'Mutation', renameSlideshow: { __typename?: 'Slideshow', id: string, name: string } };

export type SetSlideshowMutationVariables = Exact<{
  screenId: Scalars['ID']['input'];
  slideshowId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type SetSlideshowMutation = { __typename?: 'Mutation', setSlideshow: { __typename?: 'Screen', id: string, slideshow?: { __typename?: 'Slideshow', id: string } | null } };

export type GetAllMediaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMediaQuery = { __typename?: 'Query', getAllMedia: Array<{ __typename?: 'Media', id: string, url: string, thumbnailUrl: string, created: number }> };

export type GetSlideshowQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSlideshowQuery = { __typename?: 'Query', slideshow: { __typename?: 'Slideshow', id: string, name: string, slideDurationMs: number, slides: Array<{ __typename?: 'Slide', id: string, media: { __typename?: 'Media', id: string, url: string, thumbnailUrl: string } }> } };

export type GetSlideshowsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSlideshowsQueryQuery = { __typename?: 'Query', slideshows: Array<{ __typename?: 'Slideshow', id: string, name: string }> };

export type GetSlideshowsDetailedQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSlideshowsDetailedQueryQuery = { __typename?: 'Query', slideshows: Array<{ __typename?: 'Slideshow', id: string, name: string, slideDurationMs: number, slides: Array<{ __typename?: 'Slide', id: string, media: { __typename?: 'Media', id: string, url: string, thumbnailUrl: string } }> }> };

export type UploadMediaRequestQueryVariables = Exact<{
  filename: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;


export type UploadMediaRequestQuery = { __typename?: 'Query', uploadMediaRequest: { __typename?: 'S3PreSignedUploadRequest', url: string, key: string, fields: Array<{ __typename?: 'KeyValuePair', key: string, value?: string | null }> } };

export type OnMediaChangedSubscriptionVariables = Exact<{
  mediaId: Scalars['ID']['input'];
}>;


export type OnMediaChangedSubscription = { __typename?: 'Subscription', onMediaChanged: { __typename?: 'Media', id: string, url: string, thumbnailUrl: string } };

export type SetSlideDurationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  durationMs: Scalars['Int']['input'];
}>;


export type SetSlideDurationMutation = { __typename?: 'Mutation', setSlideDuration: { __typename?: 'Slideshow', id: string, slideDurationMs: number } };

export type ScreenItemFragmentFragment = { __typename?: 'Screen', id: string, name: string, slideshow?: { __typename?: 'Slideshow', id: string, name: string, slides: Array<{ __typename?: 'Slide', media: { __typename?: 'Media', id: string, thumbnailUrl: string } }> } | null } & { ' $fragmentName'?: 'ScreenItemFragmentFragment' };

export type ScreenListFragmentFragment = (
  { __typename?: 'Screen', id: string }
  & { ' $fragmentRefs'?: { 'ScreenItemFragmentFragment': ScreenItemFragmentFragment } }
) & { ' $fragmentName'?: 'ScreenListFragmentFragment' };

export type GetScreensAndSlideshowsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetScreensAndSlideshowsQuery = { __typename?: 'Query', screens: Array<(
    { __typename?: 'Screen' }
    & { ' $fragmentRefs'?: { 'ScreenListFragmentFragment': ScreenListFragmentFragment } }
  )> };

export const _FragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"_"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Media"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]} as unknown as DocumentNode<_Fragment, unknown>;
export const ScreenItemFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScreenItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Screen"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slideshow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ScreenItemFragmentFragment, unknown>;
export const ScreenListFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScreenListFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Screen"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScreenItemFragment"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScreenItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Screen"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slideshow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ScreenListFragmentFragment, unknown>;
export const AddScreenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addScreen"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addScreen"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<AddScreenMutation, AddScreenMutationVariables>;
export const AddSlideshowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addSlideshow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSlideshow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<AddSlideshowMutation, AddSlideshowMutationVariables>;
export const EditSlidesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"editSlides"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"slides"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SlideInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editSlides"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"slides"},"value":{"kind":"Variable","name":{"kind":"Name","value":"slides"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<EditSlidesMutation, EditSlidesMutationVariables>;
export const RegisterDeviceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"registerDevice"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"screenId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registerDevice"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"screenId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"screenId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deviceCode"}}]}}]}}]} as unknown as DocumentNode<RegisterDeviceMutation, RegisterDeviceMutationVariables>;
export const RenameSlideshowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"renameSlideshow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameSlideshow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<RenameSlideshowMutation, RenameSlideshowMutationVariables>;
export const SetSlideshowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setSlideshow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"screenId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"slideshowId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setSlideshow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"screenId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"screenId"}}},{"kind":"Argument","name":{"kind":"Name","value":"slideshowId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"slideshowId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slideshow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetSlideshowMutation, SetSlideshowMutationVariables>;
export const GetAllMediaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllMedia"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAllMedia"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}},{"kind":"Field","name":{"kind":"Name","value":"created"}}]}}]}}]} as unknown as DocumentNode<GetAllMediaQuery, GetAllMediaQueryVariables>;
export const GetSlideshowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSlideshow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slideshow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slideDurationMs"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetSlideshowQuery, GetSlideshowQueryVariables>;
export const GetSlideshowsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSlideshowsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slideshows"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetSlideshowsQueryQuery, GetSlideshowsQueryQueryVariables>;
export const GetSlideshowsDetailedQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSlideshowsDetailedQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slideshows"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slideDurationMs"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetSlideshowsDetailedQueryQuery, GetSlideshowsDetailedQueryQueryVariables>;
export const UploadMediaRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"uploadMediaRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filename"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"contentType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uploadMediaRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filename"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filename"}}},{"kind":"Argument","name":{"kind":"Name","value":"contentType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"contentType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"fields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode<UploadMediaRequestQuery, UploadMediaRequestQueryVariables>;
export const OnMediaChangedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"onMediaChanged"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mediaId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"onMediaChanged"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mediaId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"url"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]} as unknown as DocumentNode<OnMediaChangedSubscription, OnMediaChangedSubscriptionVariables>;
export const SetSlideDurationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setSlideDuration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"durationMs"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setSlideDuration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"durationMs"},"value":{"kind":"Variable","name":{"kind":"Name","value":"durationMs"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slideDurationMs"}}]}}]}}]} as unknown as DocumentNode<SetSlideDurationMutation, SetSlideDurationMutationVariables>;
export const GetScreensAndSlideshowsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getScreensAndSlideshows"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"screens"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScreenListFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScreenItemFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Screen"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slideshow"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"slides"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"media"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"thumbnailUrl"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScreenListFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Screen"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScreenItemFragment"}}]}}]} as unknown as DocumentNode<GetScreensAndSlideshowsQuery, GetScreensAndSlideshowsQueryVariables>;