import { ReactElement } from 'react'
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OutlineBox } from 'src/elements/OutlineBox'
import { Slides } from 'src/types'
import { usePlaylistMachine } from './usePlaylistMachine'

const Container = styled.div`
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledOutlineBox = styled(OutlineBox)`
  height: 100%;
  color: ${({ theme }) => theme.colors.blue};
`

const PlaceholderIcon = styled(FontAwesomeIcon)`
  font-size: 60px;
  margin-bottom: 20px;
`

const StyledMotionDiv = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
`

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #000;
`

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

export interface Props {
  slides?: Slides
  slideDurationMs?: number
}

const SLIDE_MILLISECONDS = 30000

export function Preview({ slides, slideDurationMs }: Props): ReactElement {
  const { t } = useTranslation()
  const currentIndex = usePlaylistMachine(
    slides?.length ?? 0,
    slideDurationMs ?? SLIDE_MILLISECONDS
  )

  const currentSlide = slides?.[currentIndex]

  return (
    <Container>
      <Wrapper>
        {currentSlide ? (
          <SlideWrapper>
            <AnimatePresence>
              <StyledMotionDiv
                key={currentSlide.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  ease: 'linear',
                  duration: 2,
                }}
              >
                {currentSlide.media && (
                  <SlideImage src={currentSlide.media.url} />
                )}
              </StyledMotionDiv>
            </AnimatePresence>
          </SlideWrapper>
        ) : (
          <StyledOutlineBox>
            <PlaceholderIcon icon={faPhotoVideo} />
            <div>{t('playlists.edit.preview.placeholderText')}</div>
          </StyledOutlineBox>
        )}
      </Wrapper>
    </Container>
  )
}
