import { useQuery } from '@apollo/client'
import { gql } from 'src/graphql'

const getSlideshowsQuery = gql(/* GraphQL */ `
  query getSlideshowsDetailedQuery {
    slideshows {
      id
      name
      slideDurationMs
      slides {
        id
        media {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`)

export function useSlideshowsWithSlides() {
  return useQuery(getSlideshowsQuery)
}
