/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation addScreen($name: String!) {\n    addScreen(name: $name) {\n      id\n      name\n    }\n  }\n": types.AddScreenDocument,
    "\n  mutation addSlideshow($name: String!) {\n    addSlideshow(name: $name) {\n      id\n      name\n    }\n  }\n": types.AddSlideshowDocument,
    "\n  mutation editSlides($id: ID!, $slides: [SlideInput!]!) {\n    editSlides(id: $id, slides: $slides) {\n      id\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.EditSlidesDocument,
    "\n                fragment _ on Media {\n                  id\n                  url\n                  thumbnailUrl\n                }\n              ": types._FragmentDoc,
    "\n  mutation registerDevice($screenId: ID!, $code: String!) {\n    registerDevice(screenId: $screenId, userCode: $code) {\n      deviceCode\n    }\n  }\n": types.RegisterDeviceDocument,
    "\n  mutation renameSlideshow($id: ID!, $name: String!) {\n    renameSlideshow(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n": types.RenameSlideshowDocument,
    "\n  mutation setSlideshow($screenId: ID!, $slideshowId: ID) {\n    setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {\n      id\n      slideshow {\n        id\n      }\n    }\n  }\n": types.SetSlideshowDocument,
    "\n  query getAllMedia {\n    getAllMedia {\n      id\n      url\n      thumbnailUrl\n      created\n    }\n  }\n": types.GetAllMediaDocument,
    "\n  query getSlideshow($id: ID!) {\n    slideshow(id: $id) {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GetSlideshowDocument,
    "\n  query getSlideshowsQuery {\n    slideshows {\n      id\n      name\n    }\n  }\n": types.GetSlideshowsQueryDocument,
    "\n  query getSlideshowsDetailedQuery {\n    slideshows {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.GetSlideshowsDetailedQueryDocument,
    "\n  query uploadMediaRequest($filename: String!, $contentType: String!) {\n    uploadMediaRequest(filename: $filename, contentType: $contentType) {\n      url\n      key\n      fields {\n        key\n        value\n      }\n    }\n  }\n": types.UploadMediaRequestDocument,
    "\n  subscription onMediaChanged($mediaId: ID!) {\n    onMediaChanged(id: $mediaId) {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n": types.OnMediaChangedDocument,
    "\n  mutation setSlideDuration($id: ID!, $durationMs: Int!) {\n    setSlideDuration(id: $id, durationMs: $durationMs) {\n      id\n      slideDurationMs\n    }\n  }\n": types.SetSlideDurationDocument,
    "\n  fragment ScreenItemFragment on Screen {\n    id\n    name\n    slideshow {\n      id\n      name\n      slides {\n        media {\n          id\n          thumbnailUrl\n        }\n      }\n    }\n  }\n": types.ScreenItemFragmentFragmentDoc,
    "\n  fragment ScreenListFragment on Screen {\n    id\n    ...ScreenItemFragment\n  }\n": types.ScreenListFragmentFragmentDoc,
    "\n  query getScreensAndSlideshows {\n    screens {\n      ...ScreenListFragment\n    }\n  }\n": types.GetScreensAndSlideshowsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addScreen($name: String!) {\n    addScreen(name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation addScreen($name: String!) {\n    addScreen(name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addSlideshow($name: String!) {\n    addSlideshow(name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation addSlideshow($name: String!) {\n    addSlideshow(name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation editSlides($id: ID!, $slides: [SlideInput!]!) {\n    editSlides(id: $id, slides: $slides) {\n      id\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation editSlides($id: ID!, $slides: [SlideInput!]!) {\n    editSlides(id: $id, slides: $slides) {\n      id\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                fragment _ on Media {\n                  id\n                  url\n                  thumbnailUrl\n                }\n              "): (typeof documents)["\n                fragment _ on Media {\n                  id\n                  url\n                  thumbnailUrl\n                }\n              "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation registerDevice($screenId: ID!, $code: String!) {\n    registerDevice(screenId: $screenId, userCode: $code) {\n      deviceCode\n    }\n  }\n"): (typeof documents)["\n  mutation registerDevice($screenId: ID!, $code: String!) {\n    registerDevice(screenId: $screenId, userCode: $code) {\n      deviceCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation renameSlideshow($id: ID!, $name: String!) {\n    renameSlideshow(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation renameSlideshow($id: ID!, $name: String!) {\n    renameSlideshow(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation setSlideshow($screenId: ID!, $slideshowId: ID) {\n    setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {\n      id\n      slideshow {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation setSlideshow($screenId: ID!, $slideshowId: ID) {\n    setSlideshow(screenId: $screenId, slideshowId: $slideshowId) {\n      id\n      slideshow {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllMedia {\n    getAllMedia {\n      id\n      url\n      thumbnailUrl\n      created\n    }\n  }\n"): (typeof documents)["\n  query getAllMedia {\n    getAllMedia {\n      id\n      url\n      thumbnailUrl\n      created\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getSlideshow($id: ID!) {\n    slideshow(id: $id) {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSlideshow($id: ID!) {\n    slideshow(id: $id) {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getSlideshowsQuery {\n    slideshows {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query getSlideshowsQuery {\n    slideshows {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getSlideshowsDetailedQuery {\n    slideshows {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getSlideshowsDetailedQuery {\n    slideshows {\n      id\n      name\n      slideDurationMs\n      slides {\n        id\n        media {\n          id\n          url\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query uploadMediaRequest($filename: String!, $contentType: String!) {\n    uploadMediaRequest(filename: $filename, contentType: $contentType) {\n      url\n      key\n      fields {\n        key\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query uploadMediaRequest($filename: String!, $contentType: String!) {\n    uploadMediaRequest(filename: $filename, contentType: $contentType) {\n      url\n      key\n      fields {\n        key\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription onMediaChanged($mediaId: ID!) {\n    onMediaChanged(id: $mediaId) {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"): (typeof documents)["\n  subscription onMediaChanged($mediaId: ID!) {\n    onMediaChanged(id: $mediaId) {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation setSlideDuration($id: ID!, $durationMs: Int!) {\n    setSlideDuration(id: $id, durationMs: $durationMs) {\n      id\n      slideDurationMs\n    }\n  }\n"): (typeof documents)["\n  mutation setSlideDuration($id: ID!, $durationMs: Int!) {\n    setSlideDuration(id: $id, durationMs: $durationMs) {\n      id\n      slideDurationMs\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ScreenItemFragment on Screen {\n    id\n    name\n    slideshow {\n      id\n      name\n      slides {\n        media {\n          id\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ScreenItemFragment on Screen {\n    id\n    name\n    slideshow {\n      id\n      name\n      slides {\n        media {\n          id\n          thumbnailUrl\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ScreenListFragment on Screen {\n    id\n    ...ScreenItemFragment\n  }\n"): (typeof documents)["\n  fragment ScreenListFragment on Screen {\n    id\n    ...ScreenItemFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getScreensAndSlideshows {\n    screens {\n      ...ScreenListFragment\n    }\n  }\n"): (typeof documents)["\n  query getScreensAndSlideshows {\n    screens {\n      ...ScreenListFragment\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;