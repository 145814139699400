import { useCallback } from 'react'
import styled from 'styled-components'
import { device } from 'src/device'
import { FragmentType, getFragmentData, gql } from 'src/graphql'
import { useAddScreen } from 'src/hooks/api/mutations/useAddScreen'
import { ScreenItem } from './ScreenItem'
import { AddScreen } from './add-screen/AddScreen'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const ScreenItemWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

const StyledAddScreen = styled(AddScreen)`
  min-height: 220px;
`

export const ScreenListFragment = gql(/* GraphQL */ `
  fragment ScreenListFragment on Screen {
    id
    ...ScreenItemFragment
  }
`)

export interface Props {
  screens?: FragmentType<typeof ScreenListFragment>[]
}

export function ScreenList(props: Props) {
  const screens = getFragmentData(ScreenListFragment, props.screens)

  const [addScreenMutate] = useAddScreen()

  const addScreen = useCallback(
    async (name: string) =>
      addScreenMutate({
        variables: {
          name,
        },
      }),
    [addScreenMutate]
  )

  return (
    <Container>
      {screens?.map((screen) => (
        <ScreenItemWrapper key={`screen-${screen.id}`}>
          <ScreenItem screen={screen} />
        </ScreenItemWrapper>
      ))}
      <ScreenItemWrapper key={`add-screen`}>
        <StyledAddScreen onAddScreen={addScreen} />
      </ScreenItemWrapper>
    </Container>
  )
}
